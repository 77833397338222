import { combineReducers } from "redux";

// Import all required reducer
import home from "./home";
import labelsConfig from "./labelsAndConfig";
import certificatesPage from "./certificates";
import coursesPage from "./courses";
import sediPage from "./sedi";
import servicePage from "./service";
import jobPage from "./job";

const rootReducers = combineReducers({
  homePage: home,
  labelsConfig,
  certificatesPage,
  coursesPage,
  sediPage,
  servicePage,
  jobPage,
});

export default rootReducers;
