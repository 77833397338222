import {
  START_FETCH_COURSES_PAGE_DATA,
  SUCCESS_FETCH_COURSES_PAGE_DATA,
  ERROR_FETCH_COURSES_PAGE_DATA,
  START_FETCH_ONLINE_COURSE_DATA,
  SUCCESS_FETCH_ONLINE_COURSE_DATA,
  ERROR_FETCH_ONLINE_COURSE_DATA,
} from "./../actions/coursesPageActionsTypes";

const initialState = {
  isLoading: false,
  coursesPageData: [],
  errorFetchingCoursesPageData: "",
  onlineCourseData: {},
};

const CoursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_COURSES_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_COURSES_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        coursesPageData: action.coursesPageData,
        errorFetchingCoursesPageData: "",
      };
    case ERROR_FETCH_COURSES_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingCoursesPageData: action.error,
      };
    case START_FETCH_ONLINE_COURSE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_ONLINE_COURSE_DATA:
      return {
        ...state,
        isLoading: false,
        onlineCourseData: action.coursesPageData,
        errorFetchingCoursesPageData: "",
      };
    case ERROR_FETCH_ONLINE_COURSE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingCoursesPageData: action.error,
      };
    default:
      return state;
  }
};
export default CoursesReducer;
