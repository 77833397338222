import { getSelectedLangData } from "@/utils/functions";

let lang = getSelectedLangData();

//push variables to datalayer
export const pageview = (url, { shallow }) => {
  console.log(
    `App is changing to ${url} ${shallow ? "with" : "without"} shallow routing`
  );
  if (!shallow) {
    setTimeout(() => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "virtualPageview",
        pagePath: url,
        pageTitle: document?.title,
        pageLanguage: lang,
        pageUrl: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
      });
    }, 2000);
  }
};
