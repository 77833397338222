import {
  START_FETCH_SEDI_PAGE_DATA,
  SUCCESS_FETCH_SEDI_PAGE_DATA,
  ERROR_FETCH_SEDI_PAGE_DATA,
  START_FETCH_SEDI_DETAIL,
  SUCCESS_FETCH_SEDI_DETAIL,
  ERROR_FETCH_SEDI_DETAIL,
  EMPTY_FETCH_SEDI_DETAIL,
} from "./../actions/sediPageActionsTypes";

const initialState = {
  isLoading: false,
  sediPageData: [],
  errorFetchingSediPageData: "",
  sediDetailsData: [],
  errorFetchingSediDetailsData: "",
};

const SediReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_SEDI_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_SEDI_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        sediPageData: action.sediPageData,
        errorFetchingSediPageData: "",
        sediDetailsData: null,
      };
    case ERROR_FETCH_SEDI_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingSediPageData: action.error,
      };
    case START_FETCH_SEDI_DETAIL:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_SEDI_DETAIL:
      return {
        ...state,
        isLoading: false,
        sediDetailsData: action.sediDetail,
        errorFetchingSediDetailsData: "",
      };
    case ERROR_FETCH_SEDI_DETAIL:
      return {
        ...state,
        isLoading: false,
        errorFetchingSediDetailsData: action.error,
      };
    case EMPTY_FETCH_SEDI_DETAIL:
      return {
        ...state,
        isLoading: false,
        sediDetailsData: {},
      };
    default:
      return state;
  }
};
export default SediReducer;
