import {
  START_FETCH_JOB_PAGE_DATA,
  SUCCESS_FETCH_JOB_PAGE_DATA,
  ERROR_FETCH_JOB_PAGE_DATA,
} from "./../actions/jobPageActionsTypes";

const initialState = {
  isLoading: false,
  jobPageData: [],
  errorFetchingJobPageData: "",
};

const JobReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_JOB_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_JOB_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        jobPageData: action.jobPageData,
        errorFetchingJobPageData: "",
      };
    case ERROR_FETCH_JOB_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingJobPageData: action.error,
      };
    default:
      return state;
  }
};
export default JobReducer;
