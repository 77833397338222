// GraphQL query build for Labels and Configs data
export const LABEL_AND_CONFIG_QUERY = `{
  labelsList (locale: lang) {
    goToPremise
    more
    premises
    backToPremises
    allCourses
    findTheClosestPremise
    contactUs
    contactLink
    englishTest
    backHome
    apply
    buyNow
    callUs
    seeMore
    availableCourses
    send
    missingField
    missingPrivacy
    thankYouTitle
    thankYouMessage
    close
    privacyPolicy
    cookiePolicy
    cookieSettings
    onlineButton
  }
  siteConfig (locale: lang) {
    instagram
    facebook
    twitter
    typeformId
    facebookIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    instagramIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    twitterIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    logoHeader {
        title
        url
        jpeg: url(imgixParams: {auto: [compress, format]})
        webp: url(imgixParams: {auto: [compress, format]})
        blurUpThumb
        alt
    }
    logoFooter {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    contactInfo
    companyInfo
    saveTheChildren
    saveTheChildrenIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    footerIcons {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    markerIcon {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    brandButtonDefaultColor {
      hex
    }
    brandBoxBackgroundColor {
      hex
    }
    brandBasicElementColor {
      hex
    }
    brandButtonHoverColor {
      hex
    }
    brandButtonPressedColor {
      hex
    }
    errorPageTitle
    errorPageDescription
    errorPageBackground {
      title
      url
      jpeg: url(imgixParams: {auto: [compress, format]})
      webp: url(imgixParams: {auto: [compress, format]})
      blurUpThumb
      alt
    }
    missingPageTitle
    missingPageDescription
    mainMenuList: headerMenu {
      ...on HomeRecord {
        id
        slug
        title
      }
      ...on CoursesTypologiesListRecord {
        slug
        title
        coursesInfoTitle
      }
      ...on CertificationsListRecord {
        title
        certificationSubtitle
      }
      ...on PremisesListRecord {
        title
        slug
        premisesIcon {
          id
        }
      }
      ... on CourseRecord {
        title
        age
      }
      ... on JobRecord {
        title
        slug
        jobOffers {
          id
        }
      }
      ... on StandardPageRecord {
        title
        slug
        standardPageSubtitle
      }
      ...on ExternalLinkPageRecord {
        title
        slug
        link
      }
    }
    subMenuList: corporateMenu {
      ... on JobRecord {
        title
        slug
        whyWorkWithNetTitle
        whyWorkWithNetDescription {
          value
        }
        jobOffers {
          title
          detail {
            value
          }
          description {
            value
          }
        }
      }
      ... on StandardPageRecord {
        title
        slug
        standardPageSubtitle
        standardPageAbstract {
          value
        }
        standardPageBackgroundImage {
          title
          url
          jpeg: url(imgixParams: {auto: [compress, format]})
          webp: url(imgixParams: {auto: [compress, format]})
          blurUpThumb
          alt
        }
        standardPageIcon {
          title
          url
          jpeg: url(imgixParams: {auto: [compress, format]})
          webp: url(imgixParams: {auto: [compress, format]})
          blurUpThumb
          alt
        }
      }
    }
    footerMenu {
      ... on JobRecord {
        title
        slug
        jobOffers {
          id
        }
      }
      ...on StandardPageRecord {
        title
        slug
        standardPageSubtitle
      }
      ... on CoursesTypologiesListRecord {
        slug
        title
        coursesInfoTitle
      }
      ... on CertificationsListRecord {
        title
        certificationSubtitle
      }
      ... on PremisesListRecord {
        title
        slug
        premisesIcon {
          id
        }
      }
      ... on JobOfferRecord {          
        title
        link
      }
    }
  }
  _site (locale: lang) {
    favicon: faviconMetaTags {
      attributes
      content
      tag
    }
    globalSeo {
      titleSuffix
      twitterAccount
      siteName
      facebookPageUrl
      fallbackSeo {
        title
        twitterCard
        description
      }
    }
    faviconMetaTags {
      tag
      content
      attributes
    }
    locales
  }
  coursesTypologiesList (locale: lang) {
    coursesTypologies {
      id
      title
      subtitle
    }
    coursesTypologiesTab {
      id
      title
      subtitle
    }
  }
  allPremises (locale: lang) {
    id
    subtitle
    title
    email
    phone
    address {
      value
    }
  }
  onlinePremise (locale: lang) {
    id
    title
    subtitle
    abstract
    slug
  }
  cancelLesson (locale: lang) {
    id
    date
    companyName
    lessonPremise
    nameAndSurname
    note
    privacy
    slug
    teacherName
    time
    title
    email
    metatags (locale: lang) {
      title
      description
      twitterCard
    }
    _seoMetaTags (locale: lang) {
        attributes
        content
        tag
    }
    seo: _seoMetaTags (locale: lang) {
        attributes
        content
        tag
    }
    noindex
    nofollow
  }
}`;

export const PREVIEW_LINK_QUERY = `{
  home (locale: lang) {
    title
    slug
  } 
  coursesTypologiesList (locale: lang) {
    subtitle
    title
  }
  allCoursesTypologies (locale: lang) {
    subtitle
    title
  }
  certificationsList (locale: lang) {
    slug
    title
  }
  premisesList (locale: lang) {
    title
    slug
  }
  allPremises (locale: lang) {
    title
    subtitle
  }
  allStandardPages (locale: lang) {
    title
    slug
  }
  job (locale: lang) {
    title
    slug
  }
  onlinePremise (locale: lang) {
    slug
    title
  }
  cancelLesson (locale: lang) {
    title
    slug
  }
}`;
