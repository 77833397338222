import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./reducers";

const middleware = [];

// if (process.env.NODE_ENV === `development`) {
//   middleware.push(logger);
// }

const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk, ...middleware))
);

export default store;
