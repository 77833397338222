/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Router, { useRouter } from "next/router";
import Script from "next/script";
import { Provider } from "react-redux";
import { createWrapper } from "next-redux-wrapper";
import store from "@/redux/store";
import dynamic from "next/dynamic";
import { pageview } from "@/lib/gtm";
const isServer = typeof window === "undefined";
import { fetchCMSDataQuery } from "@/services/requestService";

// Import master componet
const Master = dynamic(() => import("@/components/Layout/Master"), {
  ssr: false,
});

import {
  getSelectedLangData,
  set_cookie,
  getPreviewRedirectURL,
} from "@/utils/functions";

import { useEffectOnce } from "@/hooks/useEffectCustom";
import { PREVIEW_LINK_QUERY } from "@/graphql/settingsQuery";

// Import style CSS
import "@/styles/tailwind.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function MyApp({ Component, pageProps }) {
  const [isPageLoad, setPageLoad] = useState(true);
  const [lang, setLang] = useState("it");

  const { asPath, pathname, route, locale, isPreview } = useRouter();
  const router = useRouter();
  const query = router?.query;

  // set current Lang
  useEffectOnce(() => {
    if (!isServer && route !== "/404" && !isPreview) {
      let currentLang = getSelectedLangData();
      setLang(currentLang);

      // FIXME: this is just a hack to allow for case sensitive search on that particular page
      if (!asPath.includes("dovesiamo/scuole"))
        Router.push(asPath.toLowerCase(), asPath.toLowerCase(), {
          locale: currentLang,
        });
      else
        Router.push(asPath, asPath, {
          locale: currentLang,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isServer]);

  // set current lang cookie
  useEffectOnce(() => {
    if (!isServer && route !== "/404" && !isPreview) {
      set_cookie(lang);
    }
  }, [lang, isPreview]);

  useEffect(() => {
    Router.events.on("routeChangeComplete", pageview);
    Router.events.on("routeChangeError", pageview);
    return () => {
      Router.events.off("routeChangeComplete", pageview);
      Router.events.off("routeChangeError", pageview);
    };
  }, []);

  useEffect(() => {
    console.error = (error) => {
      if (/Loading chunk [\d]+ failed/.test(error?.message)) {
        window?.location?.reload();
      }
    };
    // Used for exit preview mode when open using live URL
    const exitPreviewMode = async () => {
      if (asPath?.indexOf("preview") > -1) {
        setPageLoad(false);
        const res = await fetch("/api/exit-preview?", {
          method: "GET",
        });
        if (res) {
          let slug = asPath
            ?.substring(0, asPath.indexOf("?"))
            ?.split("/")
            ?.pop();
          let query = PREVIEW_LINK_QUERY?.replace(/\lang/g, locale);
          const slugResponse = await fetchCMSDataQuery(query, true);
          let path = getPreviewRedirectURL(slug, slugResponse);

          // FIXME: this is just a hack to allow for case sensitive search on that particular page
          if (!asPath.includes("dovesiamo/scuole"))
            await Router.push(path.toLowerCase(), path.toLowerCase(), {
              locale: locale,
            });
          else
            await Router.push(path, path, {
              locale: locale,
            });
          setTimeout(() => {
            setPageLoad(true);
          }, 300);
        }
      }
    };

    exitPreviewMode();
  }, [asPath, locale, pathname, query, route]);

  return (
    <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.GOOGLE_TAG_MANAGER_ID}');
          `,
        }}
      />
      {isPageLoad && (
        <Provider store={store}>
          <Master>
            <Component {...pageProps} />
          </Master>
        </Provider>
      )}
    </>
  );
}

const makeStore = () => store;

const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(MyApp);
