const isServer = typeof window === "undefined";

/**
 * Used for return selected language data
 * @returns
 */
const getSelectedLangData = () => {
  if (!isServer) {
    let lang = "it";
    var match = document.cookie.match(
      new RegExp("(^| )" + "NEXT_LOCALE" + "=([^;]+)")
    );
    if (match?.length > 0) {
      lang = match[2];
    } else {
      set_cookie(lang);
    }
    return lang;
  } else {
    return "it";
  }
};

/**
 * Set given value in cookie
 * @param {*} value
 */
const set_cookie = (value) => {
  if (!isServer && value !== "it") {
    document.cookie = `NEXT_LOCALE=${value}; Path=/;`;
  } else {
    delete_cookie();
  }
};

/**
 * Delete values from cookie for given name
 */
const delete_cookie = () => {
  document.cookie =
    "NEXT_LOCALE=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

/**
 * Used for return active menu list
 * @returns
 */
const getAllMenuList = () => {
  const allMenuList = [
    {
      name: "Home",
      value: "home",
      url: "/",
      queryParam: "",
      slug: "home",
    },
    {
      name: "Corsi",
      value: "corsi",
      url: "/offerta/corsi",
      queryParam: "",
      slug: "corsi",
    },
    {
      name: "Certificazioni",
      value: "certificazioni",
      url: "/certificazioni",
      queryParam: "",
      slug: "certificazioni",
    },
    {
      name: "Scuole",
      value: "scuole",
      url: "/dovesiamo/scuole",
      queryParam: "/[slug]",
      slug: "scuole",
    },
    {
      name: "Test Inglese",
      value: "test-inglese",
      url: "/",
      queryParam: "",
      slug: "test-inglese",
    },
    {
      name: "Lavora con noi",
      value: "lavora-con-noi",
      url: "/jobs",
      queryParam: "",
      slug: "lavora-con-noi",
    },
  ];

  return allMenuList;
};

/**
 * Used for return active sub menu list
 * @returns
 */
const getSubmenuMenuList = () => {
  const subMenuList = [
    {
      name: "Chi siamo",
      value: "chi-siamo",
      url: "/net/chi-siamo",
      queryParam: "",
      slug: "chi-siamo",
    },
    {
      name: "Servizi",
      value: "servizi",
      url: "/net/servizi",
      queryParam: "",
      slug: "servizi",
    },
    {
      name: "Lavora con noi",
      value: "lavora-con-noi",
      url: "/lavora-con-noi",
      queryParam: "",
      slug: "lavora-con-noi",
    },
  ];

  return subMenuList;
};

/**
 * Get active server URL
 * @returns
 */
const getServerURL = () => {
  let hostname = "http://localhost:3000";
  if (typeof window !== "undefined") {
    hostname = window.location.origin;
  }
  return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
};

const getLangList = () => {
  const langList = [
    {
      name: "EN",
      value: "en",
    },
    {
      name: "IT",
      value: "it",
    },
  ];
  return langList;
};

const getWindowWidth = () => {
  let width = 1400;
  if (typeof window !== "undefined") {
    width = window.innerWidth;
  }
  return width;
};

/**
 * Middleware for call CMS API using next API routes
 * @param {*} query
 * @param {*} preview
 * @returns
 */
const fetcher = async (query, preview, variables = {}, isURL = false) => {
  const res = await fetch(`${!isURL ? getServerURL() : ""}/api/graphql`, {
    // const res = await fetch("http://localhost:3000/api/graphql", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ query, preview, variables }),
  });

  return res.json();
};

const getDistance = (lat1, lon1, lat2, lon2) => {
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;

  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  let r = 6371;

  return (c * r)?.toFixed();
};

const getClassForRenderCourseCard = () => {
  const classList = {
    1: ["col-span-3"],
    2: ["col-span-3", "col-span-3"],
    3: [
      "lg:col-span-2 md:col-span-1",
      "lg:col-span-2 md:col-span-1",
      "lg:col-span-2 md:col-span-1",
    ],
    4: [
      "lg:col-span-3 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
    ],
    5: [
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
    ],
    6: [
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
    ],
    7: [
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
    ],
    8: [
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
      "lg:col-span-3 md:col-span-2",
    ],
    9: [
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
      "lg:col-span-2 md:col-span-2",
    ],
  };
  return classList;
};

/**
 * Used for getting image ration and used that in graphQL query
 * @returns
 */
const getImageRatio = () => {
  const width = getWindowWidth();
  let imageRatioList = {};
  if (width > 1200) {
    imageRatioList.homePage = {
      topCarousel: "5x2",
    };
  } else if (width > 992) {
    imageRatioList.homePage = {
      topCarousel: "16x9",
    };
  } else if (width > 768) {
    imageRatioList.homePage = {
      topCarousel: "16x9",
    };
  } else if (width < 576) {
    imageRatioList.homePage = {
      topCarousel: "2x3",
    };
  } else if (width < 768) {
    imageRatioList.homePage = {
      topCarousel: "2x3",
    };
  } else if (width < 992) {
    imageRatioList.homePage = {
      topCarousel: "16x9",
    };
  }

  return imageRatioList;
};

const getSlugURL = (internalLink) => {
  if (internalLink) {
    if ("jobOffers" in internalLink) {
      return `/jobs`;
    }
    if ("coursesInfoTitle" in internalLink) {
      return `/offerta/${internalLink?.slug}`;
    }
    if ("premisesIcon" in internalLink) {
      return `/dovesiamo/${internalLink?.slug}`;
    }
    if ("certificationSubtitle" in internalLink) {
      return `/certificazioni`;
    }
    if ("standardPageSubtitle" in internalLink) {
      return `/net/${internalLink?.slug}`;
    }
    if ("shopLink" in internalLink) {
      return `/offerta/corsi/${internalLink?.slug}`;
    }
    if (!internalLink?.id && internalLink?.slug) {
      return `/${internalLink?.slug}`;
    }
    if (internalLink?.id && internalLink?.age) {
      return `/offerta/corsi/${internalLink?.title?.toLowerCase()}`;
    }
    if (internalLink?.link && internalLink?.slug) {
      return `${internalLink?.link}`;
    }
    if (internalLink?.id && internalLink?.subtitle) {
      return getURLSlug(internalLink);
    }
  }
  return "#!";
};

const getURLSlug = (list) => {
  const title = list?.title;
  const slug = title
    ?.toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");

  return `/dovesiamo/scuole/${slug}?id=${list?.id}`;
};

const getHeaderMenuSlug = (list) => {
  if (list?.id) {
    return "/";
  } else if (list?.jobOffers) {
    return `/jobs`;
  } else if (list?.coursesInfoTitle) {
    return `/offerta/${list?.slug}`;
  } else if (list?.premisesIcon) {
    return `/dovesiamo/${list?.slug}`;
  } else if (list?.certificationSubtitle) {
    return `/certificazioni`;
  } else if (list?.standardPageSubtitle) {
    return `/net/${list?.slug}`;
  } else if (list?.link) {
    return list?.link;
  } else {
    return list?.slug ? `/${list?.slug}` : "/";
  }
};

/**
 * This function is for check  dynamic slug and redirect on frontend
 * @returns
 */
const getPreviewRedirectURL = (slug, allSlugData) => {
  let url = "/";
  if (allSlugData?.home && slug === allSlugData?.home?.slug) {
    url = "/";
  }
  if (
    allSlugData?.coursesTypologiesList &&
    slug === allSlugData?.coursesTypologiesList?.slug
  ) {
    url = `/offerta/${allSlugData?.coursesTypologiesList?.slug}`;
  }
  if (allSlugData?.certificationsList && slug === "certificazioni") {
    url = "/certificazioni";
  }
  if (allSlugData?.premisesList && slug === allSlugData?.premisesList?.slug) {
    url = `/dovesiamo/${allSlugData?.premisesList?.slug}`;
  }
  if (allSlugData?.job && (slug === "jobs" || slug === "lavora-con-noi")) {
    url = "/jobs";
  }
  if (allSlugData?.onlinePremise && slug === allSlugData?.onlinePremise?.slug) {
    url = `/offerta/corsi/${allSlugData?.onlinePremise?.slug}`;
  }
  if (allSlugData?.cancelLesson && slug === allSlugData?.cancelLesson?.slug) {
    url = "/annulla_lezione";
  }
  allSlugData?.allStandardPages?.filter((item) => {
    item?.slug === slug ? (url = `/net/${item?.slug}`) : null;
  });
  allSlugData?.allCoursesTypologies?.filter((item) => {
    item?.slug === slug ? (url = `/offerta/corsi/${item?.slug}`) : null;
  });
  return url;
};

/* Remove trailing slash on canonical url */
const removeTrailingSlash = (url) => {
  return url.endsWith("/") ? url.slice(0, -1) : url;
};
export {
  getSelectedLangData,
  getAllMenuList,
  set_cookie,
  delete_cookie,
  getServerURL,
  getLangList,
  getWindowWidth,
  fetcher,
  getSubmenuMenuList,
  getDistance,
  getClassForRenderCourseCard,
  getImageRatio,
  getSlugURL,
  getHeaderMenuSlug,
  getPreviewRedirectURL,
  removeTrailingSlash,
};
